.sms-confirmation-field {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.sms-confirmation-field__item {
  text-align: center;
}

.sms-confirmation__phone-confirmed {
  color: #04ab1a;
}
.sms-confirmation__phone-not-confirmed {
  color: #ff0000;
}

.sms-confirmation-code-modal__content {
  padding: 10px;
}